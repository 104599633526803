.navbar {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
}
.navbar.is-active {
  z-index: 301;
}

.hero .navbar {
  background: #fff;
}

.navbar a.navbar-item.is-active,
a.navbar-item:hover,
.navbar-item strong {
  color: var(--blue);
}

button.navbar-burger {
  border: none;
  background: transparent;
}

.navbar-burger span,
.nav-toggle span {
  background: var(--blue);
}
