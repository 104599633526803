:root {
  /* Breakpoints
    $tiny: 340px;
    $mobile: 480px;
    $tablet: 769px;
    $desktop: 980px;
    $widescreen: 1180px;
  */

  --light: #f5f7fa;
  --grey-light: #d3d6db;
  --grey-darker: #222324;
  --white: #fff;
  --cyan: #33e0ff;
  --humility: #777;
  --green: #428a45;
  --red: #a22c32;
  --red-hover: #782125;
  --orange: #f68b39;
  /* --yellow: #fce473; */
  --blue: #154a94;
  --blue-hover: #0f3467;
  --primary: #154a94;
  --primary--hover: #0f3467;
}

.field.has-addons {
  display: flex;
  justify-content: flex-start;
}

.field.has-addons .control {
  margin-right: -1px;
}

.field.has-addons .control .button,
.field.has-addons .control.input,
.field.has-addons .control .select select {
  border-radius: 0;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.has-addons .control:first-child .button,
.field.has-addons .control:first-child .input,
.field.has-addons .control:first-child .select select {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.field.has-addons .control:last-child .button,
.field.has-addons .control:last-child .input,
.field.has-addons .control:last-child .select select {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

@media screen and (max-width: 390px) {
  .level-left .level-item:not(:last-child),
  .level-right .level-item:not(:last-child) {
    margin-right: 7px;
  }
}

.animated.quick {
  animation-duration: 0.5s;
}
.animated.fast {
  animation-duration: 0.25s;
}

html {
  background: #eaeff5;
  /* font-size: 14px; */
}
body,
button,
input,
select,
textarea {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif;
}

@import "./button.css";
@import "./location.css";
@import "./login.css";
@import "./modal.css";
@import "./nav.css";
@import "./react-select.css";

a {
  color: var(--blue);
}

button.text-btn {
  cursor: pointer;
  border: 0;
  background: transparent;
  font-size: 1em;
  color: var(--blue);
}
button.text-btn:not(.navbar-item) {
  padding: 0;
}
button.text-btn:hover {
  color: var(--grey-darker);
}
.text-btn.navbar-item:hover,
.text-btn.navbar-item:focus {
  background-color: #fafafa;
}
.content .text-btn {
  border-bottom: 1px solid var(--grey-light);
}
.content .text-btn:hover {
  border-bottom-color: var(--blue);
}

.notification.is-danger {
  background-color: var(--red);
}
.notification.is-success {
  background-color: var(--green);
}

.box.is-shallow {
  box-shadow: 0 2px 3px hsla(0, 0%, 7%, 0.1), 0 0 0 1px hsla(0, 0%, 7%, 0.1);
}

.input:active,
.textarea:active,
.input:focus,
.textarea:focus,
.input.is-active,
.is-active.textarea {
  border-color: var(--blue);
}

.input.is-danger,
.is-danger.textarea {
  border-color: var(--red);
}
.help.is-danger {
  color: var(--red);
}

.relative {
  position: relative;
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb {
  margin-bottom: 20px;
}

.notification.is-warning a {
  color: rgba(17, 17, 17, 0.75);
}

.notification.is-success a,
.notification.is-danger a,
.notification.is-info a {
  color: rgba(255, 255, 255, 0.5);
}

.st-overlay {
  background: var(--white);
  z-index: 1;
  opacity: 0.8;
}

.st-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  border-right-color: var(--blue);
  border-top-color: var(--blue);
  margin-left: -8px;
  margin-top: -8px;
}

figure.media-left svg {
  display: block;
  height: auto;
  width: 100%;
}

.title.is-danger,
.icon.is-danger {
  color: var(--red);
}
.title.is-warning,
.icon.is-warning {
  color: var(--orange);
}
.title.is-success,
.icon.is-success {
  color: var(--green);
}
.title.is-primary,
.title.is-info,
.icon.is-primary,
.icon.is-info {
  color: var(--blue);
}

.section-title {
  margin-top: 20px;
}
.section-title + hr {
  margin-top: 5px;
}

.humility {
  color: rgba(245, 247, 250, 0.75);
  line-height: 40px;
}
.humility a {
  color: var(--light);
}
.humility a:hover {
  color: rgba(245, 247, 250, 0.75);
}

.box .hero {
  margin-left: -20px;
  margin-right: -20px;
}
.box .hero:first-child {
  margin-top: -20px;
  margin-bottom: 20px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.box .hero:last-child {
  margin-top: 20px;
  margin-bottom: -20px;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media screen and (max-width: 340px) {
  .section {
    padding: 20px 10px;
  }
  .box {
    padding: 15px;
  }
}
