.location,
.location-map {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  max-width: 300px;
  margin: 0 auto;
}

.location.is-small,
.location-map.is-small {
  max-width: 200px;
}

.location:before,
.location-map:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.35);
}

.location .marker,
.location-map .marker {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 46px;
  color: var(--red);
  text-shadow: 0 2px 2px rgba(#000, 0.5);
  transform: translate(-50%, -90%);
}
.location .marker i,
.location-map .marker i {
  display: block;
}

.location img,
.location-map img {
  display: block;
  border-radius: 50%;
}

.is-half .marker,
.is-half-mobile .marker {
  font-size: 24px;
}

.location-name {
  display: block;
  margin-top: 5px;
}

.locate {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 24px;
  color: #999;
  cursor: pointer;
}
.locate:hover {
  color: #666;
}
.locate i {
  display: block;
}

.location-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fafafa;
  width: 100%;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  transform: translateY(0);
  transition-property: box-shadow, transform !important;
  transition: 250ms;
  transition-timing-function: cubic-bezier(0.75, 0, 0.5, 1);
}
.location-button:hover:not(:active),
.location-button:focus:not(:active) {
  background: #f5f5f5;
  box-shadow: 0 4px 6px rgba(17, 17, 17, 0.1), 0 0 0 1px rgba(17, 17, 17, 0.1);
  transform: translateY(-2px);
}
.location-button:active {
  transition-duration: 0ms;
}
.location-button:visited {
  color: var(--blue);
}

.location-map {
  flex: 0 0 auto;
}
.location-map img {
  width: 60px;
  height: 60px;
}

.location-details {
  flex: 1 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 0 10px;
  flex-direction: column;
  justify-content: center;
}
.location-details p,
.location-details p:not(:last-child) {
  text-align: left;
  margin: 0;
}

.location-icon {
  flex: 0 0 auto;
  font-size: 3em;
  display: flex;
  align-items: center;
  padding-right: 10px;
}
