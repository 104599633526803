.button.is-semi-dark {
  background: rgba(34, 35, 36, 0.1);
  color: var(--light);
  border-color: transparent;
}
.button.is-semi-dark:hover {
  background: rgba(34, 35, 36, 0.25);
  color: var(--light);
  border-color: transparent;
}
.button.is-semi-dark.is-loading {
  color: transparent;
}
.button.is-semi-dark.is-loading:after {
  border-left-color: var(--light);
  border-bottom-color: var(--light);
}

.button.is-light {
  background: transparent;
  color: var(--light);
  border-color: var(--light);
}
.button.is-light:hover,
.button.is-light:focus {
  background: rgba(34, 35, 36, 0.25);
  color: var(--light);
  border-color: var(--light);
}
.button.is-light.is-loading {
  background: rgba(34, 35, 36, 0.25);
  color: transparent;
}
.button.is-light.is-loading:after {
  border-left-color: var(--light);
  border-bottom-color: var(--light);
}

.dropzone.button {
  cursor: pointer;
}

.button.is-primary.is-inverted,
.button.is-primary.is-outlined,
.button.is-primary.is-outlined[disabled],
fieldset[disabled] .button.is-primary.is-outlined {
  color: var(--blue);
}
.button.is-primary.is-outlined {
  border-color: var(--blue);
}
.button.is-primary,
.button.is-primary.is-outlined:hover,
.button.is-primary.is-outlined:focus,
.button.is-primary[disabled],
fieldset[disabled] .button.is-primary {
  background: var(--blue);
}
.button.is-primary.is-focused:not(:active),
.button.is-primary:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}
.button.is-primary.is-outlined[disabled]:hover,
.button.is-primary.is-outlined[disabled]:focus,
fieldset[disabled] .button.is-primary.is-outlined:hover,
fieldset[disabled] .button.is-primary.is-outlined:focus {
  background: var(--light);
}
.button.is-primary.is-outlined:hover,
.button.is-primary.is-outlined:focus {
  border-color: var(--blue);
}
.button.is-primary:hover,
.button.is-primary:focus {
  background: var(--blue-hover);
}

.button.is-danger {
  background: var(--red);
}
.button.is-danger:hover,
.button.is-danger:focus {
  background: var(--red-hover);
}
.button.is-danger.is-outlined {
  color: var(--red);
}
.button.is-danger.is-outlined:hover,
.button.is-danger.is-outlined:focus,
.progress.is-danger::-moz-progress-bar {
  background: var(--red);
}

.progress.is-success::-moz-progress-bar {
  background-color: var(--green);
}
.button.is-success.is-outlined {
  color: var(--green);
  border-color: var(--green);
}
.button.is-success.is-outlined.is-focused,
.button.is-success.is-outlined.is-hovered,
.button.is-success.is-outlined:focus,
.button.is-success.is-outlined:hover {
  background-color: var(--green);
  border-color: var(--green);
}

.button.is-outlined.is-loading.is-primary:after,
.button.is-outlined.is-loading.is-info:after {
  border-color: transparent transparent var(--primary) var(--primary) !important;
}
.button.is-outlined.is-loading.is-success:after {
  border-color: transparent transparent var(--green) var(--green) !important;
}
/* .button.is-outlined.is-loading.is-warning:after {
  border-color: transparent transparent var(--yellow) var(--yellow) !important;
} */
.button.is-outlined.is-loading:hover:after {
  border-color: transparent transparent var(--white) var(--white) !important;
}
