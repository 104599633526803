.login .box {
  max-width: 400px;
  width: 100%;
}

.hero.login {
  background-color: #595a5c;
  background-image: url("./images/background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
