.modal-content {
  max-height: calc(100vh - 40px);
}

.is-safari.is-ios .modal-card,
.is-safari.is-ios .modal-content {
  max-height: calc(100vh - 100px);
}

.is-native .modal-head {
  margin-bottom: 20px;
}

.is-native .modal-foot {
  margin: 20px -20px -20px;
  display: flex;
  flex-direction: row;
}

.is-native .modal-foot button,
.is-native .modal-foot a {
  width: 50%;
  border-radius: 5px;
  border-color: #d3d6db !important;
  height: 48px;
}
.is-native .modal-foot a:first-child:last-child,
.is-native .modal-foot button:first-child:last-child {
  width: 100%;
}
.is-native .modal-foot a:first-child,
.is-native .modal-foot button:first-child {
  border-right: transparent;
  border-left: transparent;
  border-bottom: transparent;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}
.is-native .modal-foot a:last-child,
.is-native .modal-foot button:last-child {
  border-right: transparent;
  border-bottom: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
