.field.has-addons .control:last-child .Select-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 32px;
}

.field.has-addons .control:last-child .Select-placeholder,
.field.has-addons
  .control:last-child
  .Select--single
  > .Select-control
  .Select-value {
  line-height: 30px;
}

.field.has-addons .control:last-child .Select-input {
  height: 30px;
}
